import React, { useEffect, useContext } from 'react'
import userManager, { signinRedirectCallback } from 'utils/oidcClientExtension'
import { useHistory } from 'react-router-dom'
import { UserContext } from "context/UserContext";
import { i18n } from "localization/i18n";
import { setCookie, getCookie } from "helpers/cookies";
import { onPostChangePinMessage } from 'utils/broadcastChannelUtils';

function SigninOidc() {
  const history = useHistory()

  const { setAuthenticated, setUserMustChangePinCode } = useContext(UserContext);

  useEffect(() => {
    async function signinAsync() {
      console.log("Loggin in before callback");
      await signinRedirectCallback()
      console.log("Loggin in after callback");
      const user = await userManager.getUser();
      
      if(user){
        console.log("User has value");
        i18n.changeLanguage(user.profile.lang);
        setAuthenticated(true);
        
        setCookie(process.env.REACT_APP_CHANGE_PIN_COOKIE_NAME, user.profile.must_change_pin);
        setUserMustChangePinCode(user.profile.must_change_pin);

        if (getCookie(process.env.REACT_APP_CHANGE_PIN_COOKIE_NAME)) {
          console.log("Cookie exists, name: " + process.env.REACT_APP_CHANGE_PIN_COOKIE_NAME);
          onPostChangePinMessage();
        }
      }
      else{
        console.log("User has no value");
      }

	   history.push('/')
    }
    signinAsync()
  }, [history, setAuthenticated, setUserMustChangePinCode])

  return (<div></div>)
}

export default SigninOidc
