import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from "history";
const browserHistory = createBrowserHistory({ basename: '' });
var reactPlugin = new ReactPlugin();
// *** Add the Click Analytics plug-in. ***
/* var clickPluginInstance = new ClickAnalyticsPlugin();
   var clickPluginConfig = {
     autoCapture: true
}; */
var appInsights = new ApplicationInsights({
    config: {
        connectionString: 'InstrumentationKey=c88d5a9d-ed80-4f1e-b54d-061af85212e9;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/;ApplicationId=c33dff24-00a3-403b-b439-4d713a368c24',
        // *** If you're adding the Click Analytics plug-in, delete the next line. ***
        extensions: [reactPlugin],
        loggingLevelTelemetry: 2,
        enableUnhandledPromiseRejectionTracking: true,
     // *** Add the Click Analytics plug-in. ***
     // extensions: [reactPlugin, clickPluginInstance],
        extensionConfig: {
          [reactPlugin.identifier]: { history: browserHistory }
       // *** Add the Click Analytics plug-in. ***
       // [clickPluginInstance.identifier]: clickPluginConfig
        }
    }
});

appInsights.loadAppInsights();
appInsights.trackPageView();

['log', 'warn', 'error'].forEach((method) => {
  const originalMethod = console[method];
  console[method] = (...args) => {
    // Send trace to Application Insights
    appInsights.trackTrace({
      message: args.join(' '), // Combine all arguments into a single message
      severityLevel: method === 'log' ? 1 : method === 'warn' ? 2 : 3, // Map methods to severity levels
    });

    // Call the original console method
    originalMethod.apply(console, args);
  };
});
export { reactPlugin, appInsights };